jQuery( function( $ ) {

    // The 404 page has a search which I assume isn't supposed to use this code
    // Since the js files are concatinated this is the only solution without rejigging the entire build process..
    if (!$('body').hasClass('error404')) {

        // search filed
        var $s = $( '#s' );
        // the search form
        var $sForm = $s.closest( 'form' );

        var $resultContainer = $('#searchresults');

        console.log( $sForm );
        $sForm.on( 'submit', function( event) {
            event.preventDefault();
            $.post(
                T5Ajax.ajaxurl,
                {
                    action:     T5Ajax.action,
                    search_term: $s.val()
                },
                function( response ) {
                    // just append the result to the search form.
                    $resultContainer.empty().append( response );
                }
            );
        });
    }
});